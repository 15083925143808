// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.justify-content-center.indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #73737380;
  width: 200px;
  padding: 0px 1px;
  border-radius: 50px;
  z-index: 1000;
  position: fixed;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  min-height: 25px;
}

.dot-content {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  margin: 15px 0.9rem;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: #A3A3A3;
  border-radius: 50%;
  margin: 0 10px;
}

.indicator-container > .dot.active {
  transform: scale(1.8);
  background-color: white;
}

a.dot.active .dot-content {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.arrow {
  display: none;
}

@media (max-width: 768px) {
  .arrow {
    display: block;
    width: 9px;
    height: 15px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/NavigationIndicator.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;EAC3B,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,UAAU;EACV,SAAS;EACT,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,cAAc;IACd,UAAU;IACV,YAAY;IACZ,eAAe;IACf,QAAQ;IACR,2BAA2B;IAC3B,aAAa;EACf;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".navigation-wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n}\n\n.justify-content-center.indicator-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #73737380;\n  width: 200px;\n  padding: 0px 1px;\n  border-radius: 50px;\n  z-index: 1000;\n  position: fixed;\n  bottom: 3%;\n  left: 50%;\n  transform: translateX(-50%);\n  min-height: 25px;\n}\n\n.dot-content {\n  width: 8px;\n  height: 8px;\n  background-color: white;\n  border-radius: 50%;\n  margin: 15px 0.9rem;\n}\n\n.dot {\n  width: 6px;\n  height: 6px;\n  background-color: #A3A3A3;\n  border-radius: 50%;\n  margin: 0 10px;\n}\n\n.indicator-container > .dot.active {\n  transform: scale(1.8);\n  background-color: white;\n}\n\na.dot.active .dot-content {\n  margin-left: 0.8rem;\n  margin-right: 0.8rem;\n}\n\n.arrow {\n  display: none;\n}\n\n@media (max-width: 768px) {\n  .arrow {\n    display: block;\n    width: 9px;\n    height: 15px;\n    position: fixed;\n    top: 50%;\n    transform: translateY(-50%);\n    z-index: 1000;\n  }\n\n  .left-arrow {\n    left: 10px;\n  }\n\n  .right-arrow {\n    right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
