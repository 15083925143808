// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    background: linear-gradient(111deg, #0A0A0A 5.9%, #0A0A0A 97.91%);
}
body {
    background: linear-gradient(111deg, #0A0A0A 5.9%, #0A0A0A 97.91%);
  }
    `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,iEAAiE;AACrE;AACA;IACI,iEAAiE;EACnE","sourcesContent":[".App {\n    background: linear-gradient(111deg, #0A0A0A 5.9%, #0A0A0A 97.91%);\n}\nbody {\n    background: linear-gradient(111deg, #0A0A0A 5.9%, #0A0A0A 97.91%);\n  }\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
